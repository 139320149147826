import { PaymentMethod } from "../../models/cart/CartDTO";
import { OrderResultDTO } from "../../models/checkout/OrderResultDTO";
import axiosInstance from "./AxiosInstance";
import {OrderDTO} from "../../models/order/OrderDTO";
import {OrderHistoryDTO} from "../../models/order/OrderHistoryDTO";
import {OrderMetaDataDTO} from "../../models/checkout/OrderMetaDataDTO";
import {UnzerPaymentDTO} from "../../pages/Checkout/Payment/Unzer/UnzerModels";

export function sendOrder(unzerPaymentDTO : UnzerPaymentDTO = {} as UnzerPaymentDTO,) {
    let api = "/api/order";

    if(unzerPaymentDTO.paymentId != null){
        api += `?paymentId=${encodeURIComponent(unzerPaymentDTO.paymentId)}&threatMetrixId=${encodeURIComponent(unzerPaymentDTO.threatMetrixUUID)}`;

        if(unzerPaymentDTO.dateOfBirth != null){
            if(typeof unzerPaymentDTO.dateOfBirth !== 'string'){
                unzerPaymentDTO.dateOfBirth = unzerPaymentDTO.dateOfBirth.toISOString().split('T')[0];
            }
            api += "&dateOfBirth=" + unzerPaymentDTO.dateOfBirth;
        }

    }

    return axiosInstance.post<OrderResultDTO>(api, {})
        .then(res => res.data);
}

export function updateOrderAddress(orderId: number, data: OrderMetaDataDTO) {
    return axiosInstance.put<OrderResultDTO>(`/api/order/customerdata/${orderId}`, data)
        .then(res => res.data);
}

export function clearCart() {
    return axiosInstance.post<OrderResultDTO>(`/api/cart/clear`, {})
        .then(res => res.data);
}

export function updateOrderPayment(orderId: number, paymentMethod: PaymentMethod, unzerPaymentDTO: UnzerPaymentDTO = {} as UnzerPaymentDTO) {
    let api = `${process.env.HOST || ""}/api/order/updateOrderPaymentMethod/${orderId}`

    if(unzerPaymentDTO.paymentId != null){
        api += `?paymentId=${encodeURIComponent(unzerPaymentDTO.paymentId)}&threatMetrixId=${encodeURIComponent(unzerPaymentDTO.threatMetrixUUID)}`;
    }

    return axiosInstance
        .put<OrderResultDTO>(
            api,
            {},
            { params: { paymentMethod } }
        )
        .then((res) => res.data);
}

export function getOrderHistory() {
    return axiosInstance.get<OrderHistoryDTO[]>("/api/order")
        .then(res => res.data);
}

export function getOrderDetails(id: number) {
    return axiosInstance.get<OrderDTO>(`/api/order/${id}/orderdetails`)
        .then(res => res.data);
}
