import React, {
    createContext, useCallback, useRef, useEffect, useState
} from "react";
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import CustomModal from "../../components/CustomModal/CustomModal";
import {CartDTO, PaymentMethod} from "../../models/cart/CartDTO";
import {CheckoutSteps} from "../../models/checkout/CheckoutSteps";
import {changePaymentMethod} from "../../redux-store/api/ShoppingCartApi";
import CustomerData from "./CustomerData/CustomerData";
import OrderSummary from "./OrderSummary/OrderSummary";
import PaymentCanceledModal from "./Payment/PaymentCanceledModal";
import PaymentAndShipping from "./PaymentAndShipping/PaymentAndShipping";
import {Stepper} from 'react-form-stepper';
import {StepStyleDTO} from "react-form-stepper/dist/components/Step/StepTypes";
import {ConnectorStyleProps} from "react-form-stepper/dist/components/Connector/ConnectorTypes";
import {scrollToTopFn} from "../../components/ScrollToTop";
import {DateHelper} from "../../helper/DateHelper";
import {UnzerPaymentDTO, UnzerResultDTO} from "./Payment/Unzer/UnzerModels";
import {v4 as generateUUID} from "uuid";
import {TradeName} from "../../components/TradeName/TradeName";
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";

const checkoutSteps = [CheckoutSteps.CUSTOMER_DATA, CheckoutSteps.PAYMENT_METHOD, CheckoutSteps.ORDER_SUMMARY];

const stepStyle: StepStyleDTO = {
    activeBgColor: "#e19a00",
    activeTextColor: "#fff",
    completedBgColor: "#393939",
    completedTextColor: "#fff",
    inactiveBgColor: "#b7bbc0",
    inactiveTextColor: "#fff",
    size: "2.5em",
    circleFontSize: "1rem",
    labelFontSize: "1rem",
    borderRadius: "50%",
    fontWeight: "700"
};

const connectorStyle: ConnectorStyleProps = {
    disabledColor: "#b7bbc0",
    activeColor: "#b7bbc0",
    completedColor: "#b7bbc0",
    size: 2,
    style: 'solid'
}

export const CheckoutContext = createContext({
    nextStep: () => {},
    prevStep: () => {},
    cart: {} as CartDTO,
    selectedPaymentMethod: PaymentMethod.PRE_PAYMENT,
    setSelectedPaymentMethod: (paymentMethod: PaymentMethod) => {},
    updateCart: (cart: CartDTO) => {},
    unzerPaymentDTO: {} as UnzerPaymentDTO,
    unzerResultHandler: (e: any, result: UnzerResultDTO) => {},
    formDataValidated: false,
    isLoading: false,
    setIsLoading: (isLoading: boolean) => {},
    uuid: generateUUID(),
    setFormDataValidated: (isValidated: boolean) => {},
    shownPaymentMethods: new Set(),
})

export const shownPaymentMethods = new Set();

function Checkout() {
    const [t] = useTranslation();
    const location = useLocation();
    const [cart, setCart] = useState<CartDTO>(undefined);
    const [currentStep, setCurrentStep] = useState(new URLSearchParams(location.search).has("paymentCanceled") ? 1 : 0);
    const [paymentCanceled, setPaymentCanceled] = useState(new URLSearchParams(location.search).has("paymentCanceled"));
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod>(cart?.paymentMethod || PaymentMethod.PRE_PAYMENT);
    const [unzerPaymentDTO, setUnzerPaymentDTO] = useState<UnzerPaymentDTO>({} as UnzerPaymentDTO);
    const [formDataValidated, setFormDataValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const uuid = generateUUID();

    useEffect(() => {
        scrollToTopFn();
    }, []);

    function nextStep() {
        const newStep = currentStep + 1;
        if (newStep >= 0 && newStep < checkoutSteps.length) {
            setCurrentStep(newStep);
            scrollToTopFn();
        }
    }

    function previousStep() {
        const previousStep = currentStep - 1;
        if (previousStep >= 0) {
            setCurrentStep(previousStep);
            scrollToTopFn();
            setFormDataValidated(false);
        }
    }

    function updateCart(cart: CartDTO) {
        setCart(cart);
    }

    function updateShipping(paymentMethod) {
        changePaymentMethod(paymentMethod).then((cart: CartDTO) => {
            updateCart(cart);
            nextStep();
        });
    }

    function unzerResultHandler(validated: boolean, unzerResult: UnzerResultDTO) {
        if(unzerResult){
            setUnzerPaymentDTO({
                paymentId: unzerResult.unzerResult.id,
                threatMetrixUUID: uuid,
                ...unzerPaymentDTO
            });
        }
        if(selectedPaymentMethod == PaymentMethod.UNZER_PAYLATER_INVOICE
        || selectedPaymentMethod == PaymentMethod.UNZER_PAYLATER_INSTALLMENT){
            setFormDataValidated(validated && DateHelper.isAdult(unzerPaymentDTO.dateOfBirth));
        }else {
            setFormDataValidated(validated);
        }
    }

    function renderScreen() {
        switch (currentStep) {
            case 0:
                return <CustomerData/>;
            case 1:
                return <PaymentAndShipping submit={(paymentMethod) => updateShipping(paymentMethod)}/>;
            case 2:
                return <OrderSummary/>;
            default:
                break;
        }
    }

    return (
        <>
            <TradeName>{t("CHECKOUT.TITLE")}</TradeName>
            <div className="container">
                <Helmet>
                    <title>{t("CHECKOUT.TITLE")}</title>
                </Helmet>
                <Stepper
                    steps={[
                        {label: t("CHECKOUT.STEPS." + CheckoutSteps.CUSTOMER_DATA)},
                        {label: t("CHECKOUT.STEPS." + CheckoutSteps.PAYMENT_METHOD)},
                        {label: t("CHECKOUT.STEPS." + CheckoutSteps.ORDER_SUMMARY)}
                    ]}
                    activeStep={currentStep}
                    styleConfig={stepStyle}
                    connectorStyleConfig={connectorStyle}
                />
                <CheckoutContext.Provider value={
                    {
                        nextStep: nextStep,
                        prevStep: previousStep,
                        selectedPaymentMethod: selectedPaymentMethod,
                        setSelectedPaymentMethod: setSelectedPaymentMethod,
                        cart: cart,
                        updateCart: updateCart,
                        unzerPaymentDTO: unzerPaymentDTO,
                        unzerResultHandler: unzerResultHandler,
                        formDataValidated: formDataValidated,
                        isLoading: isLoading,
                        setIsLoading: setIsLoading,
                        uuid: uuid,
                        setFormDataValidated: setFormDataValidated,
                        shownPaymentMethods: shownPaymentMethods,
                    }
                }>
                    {renderScreen()}
                </CheckoutContext.Provider>
            </div>

            <CustomModal open={paymentCanceled}>
                <PaymentCanceledModal close={() => setPaymentCanceled(false)}/>
            </CustomModal>
            {isLoading &&
                <LoadingOverlay/>
            }
        </>
    );
}

export default Checkout;
