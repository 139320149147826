import {CartDTO} from "../../../../models/cart/CartDTO";
import {UnzerElementID, UnzerResultDTO} from "./UnzerModels";



export class UnzerPaymentHelper {
    static initializeCard(unzerInstance: any, submitHandler: Function, loadingHandler: Function) {
        const card = unzerInstance.Card();
        const elements = [
            {name: 'number', containerId: UnzerElementID.CARD_NUMBER, onlyIframe: false},
            {name: 'expiry', containerId: UnzerElementID.CARD_EXPIRY, onlyIframe: false},
            {name: 'cvc', containerId: UnzerElementID.CARD_CVC, onlyIframe: false},
            {name: 'holder', containerId: UnzerElementID.CARD_HOLDER, onlyIframe: false},
        ];

        elements.forEach(el => {
            card.create(el.name, {
                containerId: el.containerId,
                onlyIframe: el.onlyIframe
            })
        });

        this.addResourceSubmitHandler(card, submitHandler, loadingHandler);
    }

    static initializePayLaterInvoice(unzerInstance: any, submitHandler: Function, loadingHandler: Function) {
        let invoiceResource = unzerInstance.PaylaterInvoice();

        invoiceResource.create({
            containerId: UnzerElementID.PAYLATER_INVOICE,
            customerType: 'B2C',
        })

        this.addResourceSubmitHandler(invoiceResource, submitHandler, loadingHandler);
    }

    static initializePayLaterInstallment(unzerInstance: any, submitHandler: Function, loadingHandler: Function, cartDTO: CartDTO) {

        let installmentResource = unzerInstance.PaylaterInstallment();
        installmentResource.create({
            containerId: UnzerElementID.PAYLATER_INSTALLMENT,
            amount: cartDTO.sumGross,
            currency: 'EUR'
        })

        this.addResourceSubmitHandler(installmentResource, submitHandler, loadingHandler);
    }

    private static addResourceSubmitHandler(resource: any, callBackHandler: Function, loadingHandler: Function) {
        const form = document.getElementById(UnzerElementID.GENERAL_FORM_ID);

        if (!form) {
            console.error(`[Unzer]: Couldn't find form with id: ${UnzerElementID.GENERAL_FORM_ID}`);
            console.log(`resourceObject: ${resource}`);
            return;
        }

        form?.addEventListener('submit', function (event) {
            event.preventDefault();
            loadingHandler(true);

            resource.createResource()
                .then(function (result: any) {
                    const resultDTO: UnzerResultDTO = {
                        unzerResult: result
                    }
                    callBackHandler(true, resultDTO);
                })
                .catch(function (error: any) {
                    callBackHandler(false, null);
                    console.log(error)
                    const errorHolder = document.getElementById(UnzerElementID.GENERAL_ERROR_HOLDER);
                    if (errorHolder) {
                        errorHolder.innerText = error.customerMessage || error.message || 'Error'
                    }
                }).finally(function () {
                loadingHandler(false);
            })
        });
    }
}