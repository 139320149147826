import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import DatePicker from "react-datepicker";
import {getMonth, isValid, parse} from "date-fns";
import {de} from 'date-fns/locale';


export enum DateType {
    DATE = "DATE",
    BIRTHDAY = "BIRTHDAY",
}

interface DateInputProps {
    id: string,
    dateType: DateType,
    callBack: (date: Date) => void;
    adultCheck: boolean;
    adultCallBack?: (isValid: boolean) => void;
    selectedValue?: Date;
    className?: string;
    isNeeded?: boolean;
}


export default function DateInput({
                                      id,
                                      callBack,
                                      dateType,
                                      adultCheck,
                                      adultCallBack,
                                      selectedValue,
                                      className,
                                      isNeeded,
                                  }: DateInputProps): JSX.Element {
    const [date, setDate] = useState<Date>(selectedValue);
    const [hasError, setHasError] = useState(false);
    const {t} = useTranslation();
    const [isAdult, setIsAdult] = useState<boolean>(true);
    const [hasInteracted, setHasInteracted] = useState(false);


    function handleBlur() {
        setHasInteracted(true);
        validate();
        callBack(date);
    }

    function validate() {
        if (adultCheck) {
            const isAdultResult = isAdultCheck(date);
            if (adultCallBack) {
                adultCallBack(isAdultResult);
            }
            setIsAdult(isAdultResult);
            setHasError(false);

        } else {
            setHasError(false);
        }
    }

    function isAdultCheck(birthDate: Date): boolean {

        if (!birthDate) {
            return false;
        }

        const today = new Date();
        const age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        const dayDiff = today.getDate() - birthDate.getDate();

        return age > 18 || (age === 18 && (monthDiff > 0 || (monthDiff === 0 && dayDiff >= 0)));
    }

    function changeHandler(e: any) {
        const regex = /^\d{1,2}\.\d{1,2}\.\d{4}$/;
        if (typeof e === "string") {
            if (regex.test(e)) {
                setDate(parse(e, 'dd.MM.yyyy', new Date()));
                if (date && isValid(date)) {
                    validate();
                }
            }
        } else {
            setDate(e);
            if (date && isValid(date)) {
                validate();
            }
        }
    }

    useEffect(() => {
        if (selectedValue) {
            validate();
        }
    }, []);


    const months = [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
    ];

    return (
        <div className={className}>
            <label htmlFor={id}
                   className="form-label-small-bold d-block">
                {t(`ENUMS.DATE_TYPE.${dateType}.LABEL`)}
                {isNeeded && <span>&nbsp;*</span>}
            </label>

            <DatePicker
                id={id}
                selected={date}
                locale={de}
                dateFormat="dd.MM.yyyy"
                className={`form-control ${hasInteracted && (hasError || adultCheck && !isAdult) ? "date-input-alert" : ""}`}
                placeholderText={"TT.MM.JJJJ"}
                onChange={(date: Date | null) => changeHandler(date)}
                onBlur={handleBlur}
                onFocus={() => setHasInteracted(true)}
                renderCustomHeader={({
                                         date,
                                         changeYear,
                                         changeMonth,
                                         decreaseMonth,
                                         increaseMonth,
                                         prevMonthButtonDisabled,
                                         nextMonthButtonDisabled,
                                     }) => (
                    <div>
                        <select
                            value={new Date(date).getFullYear()}
                            onChange={({target: {value}}) => changeYear(Number(value))}
                        >
                            {Array.from({length: 150}, (_, i) => {
                                const year = new Date().getFullYear() - i;
                                return <option key={year} value={year}>{year}</option>;
                            })}
                        </select>
                        <select
                            value={months[getMonth(date)]}
                            onChange={({target: {value}}) =>
                                changeMonth(months.indexOf(value))

                            }
                        >
                            {months.map((option) => (
                                <option key={option} value={option}>
                                    {t('ENUMS.MONTHS.' + option)}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
            />

            {hasInteracted && adultCheck && !isAdult &&
                <p className={"date-picker-alert"}>{t(`ENUMS.DATE_TYPE.NOT_ADULT`)}</p>
            }
            {hasInteracted && hasError &&
                <p className={"date-picker-alert"}>{t(`ENUMS.DATE_TYPE.${dateType}.ERROR`)}</p>
            }

        </div>
    );
}
