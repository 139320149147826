import {Field, Form, Formik} from "formik";
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {AnalyticsHelper} from "../../../analytics/AnalyticsHelper";
import {PaymentMethod} from "../../../models/cart/CartDTO";
import CustomModal from "../../../components/CustomModal/CustomModal";
import CartPrice from "../../ShoppingCart/components/CartPrice/CartPrice";
import RedeemVoucher from "../../ShoppingCart/components/RedeemVoucher/RedeemVoucher";
import AcceptedPaymentMethods from "../../ShoppingCart/components/AcceptedPaymentMethods/AcceptedPaymentMethods";
import {useSelector} from "react-redux";
import UnzerPaymentPlugin from "../Payment/Unzer/UnzerPaymentPlugin";
import {CheckoutContext} from "../Checkout";
import {UnzerElementID, UnzerPaymentDTO} from "../Payment/Unzer/UnzerModels";
import DateInput, {DateType} from "../../../components/DateInput/DateInput";
import {DateHelper} from "../../../helper/DateHelper";

interface Props {
    submit: (paymentMethod: PaymentMethod, unzerPaymentDTO?: UnzerPaymentDTO) => void;
}

export const paymentMethods = [
    {
        type: PaymentMethod.PRE_PAYMENT,
        icon: "/payment/vorkasse.png"
    },
    {
        type: PaymentMethod.PAY_PAL,
        icon: "/payment/paypal.png"
    },
    {
        type: PaymentMethod.PICKUP,
        icon: "/payment/wc-money-payment.png"
    },
    {
        type: PaymentMethod.UNZER_PAYLATER_INVOICE,
        icon: "/payment/rechnung.svg"
    },
    {
        type: PaymentMethod.UNZER_PAYLATER_INSTALLMENT,
        icon: "/payment/wc-ratepay.png"
    },
    {
        type: PaymentMethod.UNZER_CARD,
        icon: "/payment/visa-mastercard.svg"
    },
    //Will be integrated soon™
    // {
    //     type: PaymentMethod.UNZER_APPLEPAY,
    //     icon: "/payment/wc-money-payment.png"
    // },
    // {
    //     type: PaymentMethod.UNZER_GOOGLEPAY,
    //     icon: "/payment/wc-money-payment.png"
    // }
];

export const adultPaymentMethods = [
    PaymentMethod.UNZER_PAYLATER_INVOICE,
    PaymentMethod.UNZER_PAYLATER_INSTALLMENT
]

function PaymentAndShipping({submit}: Props) {
    const {t} = useTranslation();
    const [showPaymentMethodInformation, setShowPaymentMethodInformation] = useState<PaymentMethod>()
    const {
        prevStep,
        cart,
        updateCart,
        selectedPaymentMethod,
        setSelectedPaymentMethod,
        unzerResultHandler,
        formDataValidated,
        isLoading,
        setIsLoading,
        unzerPaymentDTO,
        uuid,
        shownPaymentMethods,
    } = useContext(CheckoutContext)

    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [validBirthDay, setIsValidBirthDay] = useState<boolean>(false)

    function onBirthDayChange(birthday: Date): void {
        unzerPaymentDTO.dateOfBirth = birthday;
    }

    function isAdultHandler(isAdult: boolean): void {
        setIsValidBirthDay(isAdult);
    }


    function getSubFormElements(paymentMethod: PaymentMethod) {

        if (paymentMethod != selectedPaymentMethod) {
            return <></>;
        }

        switch (paymentMethod) {
            case PaymentMethod.UNZER_PAYLATER_INVOICE:
            case PaymentMethod.UNZER_PAYLATER_INSTALLMENT:
                return <>

                    <DateInput className={"form-group mb-3"} id={'unzer-birth-day-input'} dateType={DateType.BIRTHDAY} callBack={onBirthDayChange} isNeeded={true}
                                   adultCheck={true} adultCallBack={isAdultHandler} selectedValue={
                        unzerPaymentDTO.dateOfBirth
                            ? new Date(unzerPaymentDTO.dateOfBirth)
                            : null
                    }/>

                    <UnzerPaymentPlugin paymentMethod={selectedPaymentMethod}
                                        cartDTO={cart} submitHandler={unzerResultHandler}
                                        loadingHandler={setIsLoading}
                                        threatMetrixUUID={uuid}/>
                </>

            case PaymentMethod.UNZER_CARD:
            case PaymentMethod.UNZER_APPLEPAY:
            case PaymentMethod.UNZER_GOOGLEPAY:
            case PaymentMethod.UNZER_PAYPAL:
                setIsValidBirthDay(true);
                return <UnzerPaymentPlugin paymentMethod={selectedPaymentMethod}
                                           cartDTO={cart} submitHandler={unzerResultHandler}
                                           loadingHandler={setIsLoading}
                                           threatMetrixUUID={uuid}/>

            default:
                setIsValidBirthDay(true);
                return <></>;
        }
    }

    useEffect(() => {
        if (formDataValidated) {
            submit(selectedPaymentMethod, unzerPaymentDTO);
            AnalyticsHelper.trackPaymentMethod(selectedPaymentMethod, cart);
        }
    }, [formDataValidated]);


    return (
        <div className="row">
            {prevStep &&
                <div className="col-12 text-end col-lg-11 offset-lg-1">
                    <div className="d-flex align-items-baseline mb-3 ms-3" onClick={() => prevStep()}>
                        <i className="fas fa-long-arrow-alt-left"/>
                        <div className="ms-2 cursor">{t("CHECKOUT.PAYMENT_OPTIONS.BACK_TO_CUSTOMER_DATA")}</div>
                    </div>
                </div>
            }
            <div className="col-12 col-lg-6 offset-0 offset-lg-1">
                <div className="panel">
                    <div className="heading">
                        {t("CHECKOUT.PAYMENT_OPTIONS.PLEASE_CHOOSE_PAYMENT_OPTION")}
                    </div>
                    <div className="payment-wrapper col-12 mb-3">
                        <Formik
                            initialValues={{
                                paymentMethod: selectedPaymentMethod,
                            }}
                            onSubmit={({paymentMethod}) => {

                                if(selectedPaymentMethod != PaymentMethod.UNZER_PAYLATER_INSTALLMENT
                                    && selectedPaymentMethod != PaymentMethod.UNZER_PAYLATER_INVOICE) {
                                    setIsValidBirthDay(true);
                                }

                                setIsSubmitted(true);
                                setSelectedPaymentMethod(paymentMethod);
                                if (!paymentMethod.includes("UNZER")) {
                                    submit(paymentMethod, {} as UnzerPaymentDTO);
                                    AnalyticsHelper.trackPaymentMethod(paymentMethod, cart);
                                }
                            }}
                        >
                            {({values}) => {
                                useEffect(() => {
                                    setSelectedPaymentMethod(values.paymentMethod);
                                }, [values.paymentMethod]);
                                return (
                                    <Form id={UnzerElementID.GENERAL_FORM_ID}>
                                        <div>
                                            {paymentMethods.map((paymentMethod) => {
                                                    if (unzerPaymentDTO.dateOfBirth) {
                                                        if (!DateHelper.isAdult(unzerPaymentDTO.dateOfBirth)
                                                            && adultPaymentMethods.includes(paymentMethod.type)
                                                            && !shownPaymentMethods.has(paymentMethod.type)) {
                                                            return <></>
                                                        }
                                                    }

                                                    //we dont' want to hide a paymentMethod if the user has seen it in this context before
                                                    shownPaymentMethods.add(paymentMethod.type);

                                                    // if (paymentMethod.type == "PAY_PAL" && !usePayPal) {
                                                    //     return <CookieChangeHint cookieName={"PayPal"}></CookieChangeHint>
                                                    // }

                                                    return <div className="payment-method-container"
                                                                key={paymentMethod.type}>
                                                        <label className="form-check-label d-flex align-items-center"
                                                               htmlFor={paymentMethod.type}>
                                                            <div className="form-check">
                                                                <Field
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="paymentMethod"
                                                                    id={paymentMethod.type}
                                                                    value={paymentMethod.type}
                                                                    disabled={isLoading}
                                                                />
                                                                <span className="ms-2 fw-bold">
                                                            {t("ENUMS.PAYMENT_METHODS." + paymentMethod.type)}

                                                        </span>
                                                            </div>
                                                            <div className="icon-container">
                                                                <img src={paymentMethod.icon} alt={paymentMethod.type}/>
                                                            </div>
                                                        </label>
                                                        {getSubFormElements(paymentMethod.type)}
                                                    </div>
                                                }
                                            )}
                                        </div>
                                        <div className="col-12 mt-5 ">
                                            {!formDataValidated && isSubmitted && (
                                                <p className={"alert-warning mb-3"}>{t('CHECKOUT.PAYMENT_OPTIONS.CHECK_INPUT_ALERT')}</p>
                                            )}
                                            <button type="submit" id={UnzerElementID.RESOURCE_SUBMIT_BUTTON}
                                                    className="btn btn-green w-100"
                                                    disabled={isLoading || !validBirthDay}>
                                                {t("CHECKOUT.PAYMENT_OPTIONS.CONTINUE_TO_SUMMARY")}
                                            </button>

                                        </div>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
            <div className="col-12 col-lg-4">
                <div className="panel">
                    <RedeemVoucher updateCart={(cart) => updateCart(cart)}
                                   cart={cart}/>
                </div>
                <div className="panel">
                    <CartPrice sumNet={cart.sumNet}
                               sumGross={cart.sumGross}
                               vat={cart.vat}
                               discount={cart.discount > 0 ? cart.discount : null}
                               shipping={cart.shipping > 0 ? cart.shipping : null}
                    />
                </div>
                <div className="panel">
                    <AcceptedPaymentMethods/>
                </div>
            </div>
            <CustomModal open={!!showPaymentMethodInformation}>
                <div className="custom-modal car-details-modal">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="custom-modal-title d-inline-block">
                                {t("PAYMENT_METHOD_INFORMATION.TITLE")}
                            </h5>
                            <button type="button" className="btn-close"
                                    onClick={() => setShowPaymentMethodInformation(undefined)} aria-label="Close">
                            </button>
                        </div>
                        <div className="modal-body">
                            {t('PAYMENT_METHOD_INFORMATION.' + showPaymentMethodInformation)}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className={`btn btn-primary`}
                                    onClick={() => setShowPaymentMethodInformation(undefined)}>
                                {t("PAYMENT_METHOD_INFORMATION.CLOSE")}
                            </button>
                        </div>
                    </div>
                </div>
            </CustomModal>

        </div>

    );
}

export default PaymentAndShipping;
