import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {OrderDTO} from "../../../../models/order/OrderDTO";
import {useNavigate, useParams} from "react-router-dom";
import {getOrderDetails} from "../../../../redux-store/api/OrderApi";
import Helmet from "react-helmet";
import {TradeName} from "../../../../components/TradeName/TradeName";
import moment from "moment";
import OrderPosition from "../OrderPosition/OrderPosition";
import { NumericFormat } from "react-number-format";
import CheckoutPrice from "../../../../components/CheckoutPrice/CheckoutPrice";
import Tracking from "../Tracking/Tracking";

function OrderDetails() {
    const [t] = useTranslation();
    const {id} = useParams<{ id: string }>();
    const [order, setOrder] = useState<OrderDTO>(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        loadOrderDetails();
    }, []);

    function loadOrderDetails() {
        setLoading(true);
        getOrderDetails(+id).then((res) => {
            setOrder(res);
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <div className="order-history-wrapper">
            <Helmet>
                <title>{t("SETTINGS.ORDER_HISTORY.TITLE")}</title>
            </Helmet>
            {order &&
                <TradeName>{t("SETTINGS.ORDER_HISTORY.ORDER_NO")} {moment(order.date).format('DD.MM.YYYY')}</TradeName>
            }
            <div className="container my-3">
                {loading &&
                    <div className="order-overview-loading"/>
                }
                {!loading && order &&
                    <>
                        <p><span className="back-to-orders"
                                 onClick={() => navigate("/einstellungen/bestellungen")}><i
                            className="fas fa-angle-left me-1"/>{t("SETTINGS.ORDER_HISTORY.BACK_TO_ORDERS")}</span>
                        </p>
                        <div className="container order-details mt-3">
                            <div className="order-header py-3">
                                <div className="row">
                                    <div className="col-6 col-sm-4 col-lg-2 mb-3">
                                        <div className="title">{t("SETTINGS.ORDER_HISTORY.DATE")}</div>
                                        <div>{moment(order.date).format('DD.MM.YYYY, HH:mm')} {t('GLOBAL.CLOCK')}</div>
                                    </div>
                                    <div className="col-6 col-sm-4 col-lg-2 mb-3">
                                        <div className="title">{t("SETTINGS.ORDER_HISTORY.ORDER_NUMBER")}</div>
                                        <div>{order.id}</div>
                                    </div>
                                    <div className="col-6 col-sm-4 col-lg-2 mb-3">
                                        <div className="title">{t("SETTINGS.ORDER_HISTORY.STATUS")}</div>
                                        <div>{t("ENUMS.ORDER_STATUS." + order.status)}</div>
                                    </div>
                                    <div className="col-6 col-sm-4 col-lg-2 mb-3">
                                        <div className="title">{t("SETTINGS.ORDER_HISTORY.PAYMENT_METHOD")}</div>
                                        <div>{t("ENUMS.PAYMENT_METHODS." + order.paymentMethod)}</div>
                                    </div>
                                    <div className="col-6 col-sm-4 col-lg-2 mb-3">
                                        <div className="title">{t("SETTINGS.ORDER_HISTORY.DELIVERY_ADDRESS")}</div>
                                        <div>{order.deliveryAddressString}</div>
                                        {order.phoneNumber &&
                                            <div>{t('SETTINGS.ORDER_HISTORY.PHONE_SHORT')}{order.phoneNumber}</div>
                                        }
                                    </div>
                                    <div className="col-6 col-sm-4 col-lg-2 mb-3">
                                        <div className="title">{t("SETTINGS.ORDER_HISTORY.INVOICE_ADDRESS")}</div>
                                        <div>{order.invoiceAddressString}</div>
                                    </div>
                                </div>
                                {order.remark && <div className="row">
                                    <div className="col-12">
                                      <div className="title">{t("SETTINGS.ORDER_HISTORY.REMARK")}</div>
                                      <div>{order.remark}</div>
                                    </div>
                                  </div>
                                }
                            </div>
                            {order.positions.map((position, index) => (
                                <div key={position.id}>
                                    <div className="row order-position-header py-2">
                                        <div className="col-12">
                                            <strong>{index + 1}.
                                                Position: {t(`CART.POSITION_TYPE.${position.positionType}`)}</strong>
                                            {" "}
                                            {position.carDescription &&
                                                <span>{t("CART.POSITION_FOR_CAR")} {position.carDescription}</span>
                                            }
                                        </div>
                                    </div>
                                    <OrderPosition position={position}/>
                                </div>
                            ))}
                            {order.voucherCode &&
                                <div className="py-3">
                                    <div className="row order-position-header py-2 align-items-center">
                                        <div className="col">
                                            <strong>{t('CART.ITEM_TYPE.VOUCHER')}</strong>
                                        </div>
                                    </div>
                                    <div className="order-position">
                                        <div className="row py-2 voucher">
                                            <div className="col-8">
                                                {t('CART.ITEM_TYPE.VOUCHER')} {`"${order.voucherCode}"`}
                                            </div>
                                            <div className="col-4 text-end fw-bold">
                                                <NumericFormat
                                                    value={order.discount}
                                                    displayType="text"
                                                    decimalSeparator=","
                                                    decimalScale={2}
                                                    fixedDecimalScale
                                                    suffix="€"
                                                    prefix="- "
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="row order-footer py-4">
                                <div className="col-8 text-end">
                                    {t("SETTINGS.ORDER_HISTORY.TOTAL_PRICE_GROSS")}:
                                </div>
                                <div className="col-4 text-end">
                                    <NumericFormat
                                        value={order.sumGross}
                                        displayType="text"
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale
                                        suffix="€"
                                    />
                                </div>
                            </div>
                            {order.invoiceFileAvailable &&
                                <div className="row">
                                    <div className="col-12 text-end py-2">
                                        <a href={'/api/order/' + order.id + '/invoice'} className="btn btn-primary">
                                            {t("SETTINGS.ORDER_HISTORY.INVOICE_DOWNLOAD")}
                                        </a>
                                    </div>
                                </div>
                            }
                        </div>
                        <CheckoutPrice
                            noMarginBottom
                            sumNet={order.sumNet}
                            sumGross={order.sumGross}
                            vat={order.vat}
                            shipping={order.shipping}
                            discount={order.discount}/>

                        <div className="container tracking-details my-3">
                            <Tracking trackingEntries={order.deliveryTrackingEntries}/>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default OrderDetails;
