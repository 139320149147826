import {UnzerElementID, UnzerHTMLClasses} from "./UnzerModels";
import React from "react";

export class UnzerHtmlHelper {


    static getCardHtmlElements() {
        return (
            <div className={"unzerUI form"}>
                <div className="field">
                    {this.getRenderField(UnzerElementID.CARD_NUMBER, UnzerHTMLClasses.INPUT)}
                </div>
                <div className="two fields">
                    <div className="field ten wide">
                        {this.getRenderField(UnzerElementID.CARD_EXPIRY, UnzerHTMLClasses.INPUT)}
                    </div>
                    <div className="field six wide">
                        {this.getRenderField(UnzerElementID.CARD_CVC, UnzerHTMLClasses.INPUT)}
                    </div>
                </div>
                <div className="field">
                    {this.getRenderField(UnzerElementID.CARD_HOLDER, UnzerHTMLClasses.INPUT)}
                </div>
            </div>
        );
    }

    static getGooglePayHtmlElements() {
        return (
            <div className={"unzerUI form"}>
                {this.getRenderField(UnzerElementID.GOOGLEPAY_BUTTON)}
                {this.getRenderField(UnzerElementID.GOOGLEPAY_ERROR, UnzerHTMLClasses.FIELD)}
                {this.getRenderField(UnzerElementID.GOOGLEPAY_SUCCESS, UnzerHTMLClasses.FIELD)}
            </div>

        );
    }

    static getPayLaterInvoiceHtmlElements() {
        return <div className={"unzerUI form"}>
            {this.getRenderField(UnzerElementID.PAYLATER_INVOICE)}
        </div>

    }

    static getPayLaterInstallmentHtmlElements() {
        return <div className={"unzerUI form"}>
            {this.getRenderField(UnzerElementID.PAYLATER_INSTALLMENT)}
        </div>
    }

    private static getRenderField(id: string, className: string = '') {
        return <div id={id} className={`${className}`}></div>
    }
}