export class StringHelper {
    static toSnakeCase(str: string, encode: boolean = true): string {
        if(str == null){
            return '';
        }

        const formattedStr = str.toLowerCase().replace(/ /g, '_');
        return encode ? encodeURIComponent(formattedStr) : formattedStr;
    }

    static GermanToISODate(str: string): string {
        const split = str.split(".")

        if(split.length != 3){
            console.error("[GermanToISODate] Please provide valid date string!");
            return str;
        }

        return `${split[2]}-${split[1]}-${split[0]}`;
    }
}