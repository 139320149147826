import React, {useContext, useState} from "react";
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {ErrorMessage, Field, Form, Formik, FormikProps} from "formik";
import {getFormFieldValidationStyles} from "../../../helper/formikHelper";
import * as yup from "yup";
import {CountryOptionType} from "../../../models/shared/Types";
import {countryZipRegex, getCountryList} from "../../../models/checkout/CountryCodes";
import {Typeahead} from "react-bootstrap-typeahead";
import UserContext from "../../../context/UserContext";
import {UserContextType} from "../../../context/UserContextType";
import {editUserDetails} from "../../../redux-store/api/UserApi";
import {UserDTO} from "../../../models/shared/UserDTO";
import {ReactComponent as CheckSvg} from '../../../assets/icons/svg/check.svg';

function UserData() {
    const {t} = useTranslation();
    const [changedUserData, setChangedUserData] = useState(false);
    const [error, setError] = useState(false);
    const {user, loggedIn, loginUser} = useContext(UserContext) as UserContextType;


    const validationSchema = yup.object().shape({
        firstName: yup.string().required(t("FORMS.VALIDATIONS.REQUIRED")),
        lastName: yup.string().required(t("FORMS.VALIDATIONS.REQUIRED")),
        street: yup.string().required(t("FORMS.VALIDATIONS.REQUIRED")),
        zipCode: yup.string().required(t("FORMS.VALIDATIONS.REQUIRED")).when(['country'], (country: any, schema) => {
            return country ? schema.matches(countryZipRegex[country], t("FORMS.VALIDATIONS.ZIP_FORMAT")) : schema;
        }),
        city: yup.string().required(t("FORMS.VALIDATIONS.REQUIRED")),
        country: yup.string().required(t("FORMS.VALIDATIONS.REQUIRED")),
        phoneNumber: yup.string().required(t("FORMS.VALIDATIONS.REQUIRED")),
        email: yup.string().email(t("FORMS.VALIDATIONS.MAIL_PATTERN"))
            .required(t("FORMS.VALIDATIONS.REQUIRED")),
        dateOfBirth: yup.date().max(new Date().toISOString()),
    });
    const countryOptions: CountryOptionType[] = getCountryList(t);

    function submitForm(values: UserDTO) {
        editUserDetails(values).then((user) => {
            setChangedUserData(true);
            loginUser(user);
        }).catch(err => {
            console.error("ERROR in changing user data");
            setError(true);
        });
    }

    return (
        <div className="user-data-wrapper">
            <Helmet>
                <title>{t("SETTINGS.CHANGE_USER_DATA")}</title>
            </Helmet>
            <div className="container">
                <h1 className="text-dark pb-4"><i>{t("SETTINGS.CHANGE_USER_DATA")}</i></h1>
                <p>
                    <Link to="/einstellungen">
                        <i className="fas fa-long-arrow-alt-left me-1"/> {t("SETTINGS.BACK")}
                    </Link>
                </p>
                {loggedIn && user &&
                    <div className="user-data-box">
                        {changedUserData &&
                            <div className="message">
                                <CheckSvg height={'1rem'} className={'me-3'}/>{t("SETTINGS.USER_DATA.USER_DATA_CHANGED")}
                            </div>
                        }
                        {error &&
                            <div className="message">
                                {t("SETTINGS.USER_DATA.ERROR_CHANGING_USER_DATA")}
                            </div>
                        }
                        {!changedUserData && !error &&
                            <Formik
                                initialValues={user}
                                validationSchema={validationSchema}
                                onSubmit={submitForm}
                                enableReinitialize
                            >
                                {(props: FormikProps<any>) => (
                                    <Form>
                                        <div className="container form-container">
                                            <div className="row">
                                                <div className="col-12">
                                                    <label htmlFor="title"
                                                           className="form-label">{t("FORMS.CUSTOMER_DATA.TITLE")}</label>
                                                </div>
                                                <fieldset className="col-12 my-2" onClick={(e) => { // <- note: onClick not onChange
                                                    // Check if currently selected value in group is the currently clicked item and its already active
                                                    const target: HTMLInputElement = e.target as HTMLInputElement
                                                    if (props.values.title === target.id && target.checked) {
                                                        target.checked = false; // deactivate it
                                                        props.setFieldValue("title", null) // set value to null since nothing is selected
                                                    }
                                                }}>
                                                    <div className="form-check form-check-inline">
                                                        <Field className="form-check-input"
                                                               type="radio"
                                                               name="title"
                                                               id={t("FORMS.CUSTOMER_DATA.MR")}
                                                               value={t("FORMS.CUSTOMER_DATA.MR")}
                                                        />
                                                        <label className="form-check-label"
                                                               htmlFor={t("FORMS.CUSTOMER_DATA.MR")}>
                                                            {t("FORMS.CUSTOMER_DATA.MR")}
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <Field className="form-check-input"
                                                               type="radio"
                                                               name="title"
                                                               id={t("FORMS.CUSTOMER_DATA.MS")}
                                                               value={t("FORMS.CUSTOMER_DATA.MS")}
                                                        />
                                                        <label className="form-check-label"
                                                               htmlFor={t("FORMS.CUSTOMER_DATA.MS")}>
                                                            {t("FORMS.CUSTOMER_DATA.MS")}
                                                        </label>
                                                    </div>
                                                </fieldset>
                                                <div className="col-12 my-2">
                                                    <label htmlFor="firstName"
                                                           className="form-label">{t("FORMS.CUSTOMER_DATA.FIRST_NAME")}</label>
                                                    <Field
                                                        className={getFormFieldValidationStyles(props.errors, props.touched, "firstName")}
                                                        type="text"
                                                        name="firstName"
                                                        placeholder={t("FORMS.CUSTOMER_DATA.FIRST_NAME")}
                                                    />
                                                    <ErrorMessage name="firstName"
                                                                  render={msg => <div
                                                                      className="invalid-feedback">{msg}</div>}/>
                                                </div>
                                                <div className="col-12 my-2">
                                                    <label htmlFor="lastName"
                                                           className="form-label">{t("FORMS.CUSTOMER_DATA.LAST_NAME")}</label>
                                                    <Field
                                                        className={getFormFieldValidationStyles(props.errors, props.touched, "lastName")}
                                                        type="text"
                                                        name="lastName"
                                                        placeholder={t("FORMS.CUSTOMER_DATA.LAST_NAME")}
                                                    />
                                                    <ErrorMessage name="lastName"
                                                                  render={msg => <div
                                                                      className="invalid-feedback">{msg}</div>}/>
                                                </div>
                                                <div className="col-12 my-2">
                                                    <label htmlFor="street"
                                                           className="form-label">{t("FORMS.CUSTOMER_DATA.STREET")}</label>
                                                    <Field
                                                        className={getFormFieldValidationStyles(props.errors, props.touched, "street")}
                                                        type="text"
                                                        name="street"
                                                        placeholder={t("FORMS.CUSTOMER_DATA.STREET")}
                                                    />
                                                    <ErrorMessage name="street"
                                                                  render={msg => <div
                                                                      className="invalid-feedback">{msg}</div>}/>
                                                </div>
                                                <div className="col-12 my-2">
                                                    <label htmlFor="zipCode"
                                                           className="form-label">{t("FORMS.CUSTOMER_DATA.ZIP_CODE")}</label>
                                                    <Field
                                                        className={getFormFieldValidationStyles(props.errors, props.touched, "zipCode")}
                                                        type="text"
                                                        name="zipCode"
                                                        placeholder={t("FORMS.CUSTOMER_DATA.ZIP_CODE")}
                                                    />
                                                    <ErrorMessage name="zipCode"
                                                                  render={msg => <div
                                                                      className="invalid-feedback">{msg}</div>}/>
                                                </div>
                                                <div className="col-12 my-2">
                                                    <label htmlFor="city"
                                                           className="form-label">{t("FORMS.CUSTOMER_DATA.CITY")}</label>
                                                    <Field
                                                        className={getFormFieldValidationStyles(props.errors, props.touched, "city")}
                                                        type="text"
                                                        name="city"
                                                        placeholder={t("FORMS.CUSTOMER_DATA.CITY")}
                                                    />
                                                    <ErrorMessage name="city"
                                                                  render={msg => <div
                                                                      className="invalid-feedback">{msg}</div>}/>
                                                </div>
                                                <div className="col-12 my-2">
                                                    <label htmlFor="country"
                                                           className="form-label">{t("FORMS.CUSTOMER_DATA.COUNTRY")}</label>
                                                    <Field
                                                        name="country"
                                                    >
                                                        {({
                                                              form: {values}
                                                          }) => (
                                                            <Typeahead options={countryOptions}
                                                                       id="country-autocomplete"
                                                                       inputProps={{
                                                                           autoComplete: "nope",
                                                                           required: true,
                                                                           name: "country",
                                                                           className: getFormFieldValidationStyles(props.errors, props.touched, "country")
                                                                       }}
                                                                       paginate={false}
                                                                       placeholder={t("FORMS.CUSTOMER_DATA.COUNTRY")}
                                                                       emptyLabel={t("FORMS.CUSTOMER_DATA.NO_MATCHED_COUNTRIES")}
                                                                       onChange={(e: CountryOptionType[]) => {
                                                                           props.setFieldValue("country", e[0]?.code);
                                                                       }}
                                                                       onBlur={props.handleBlur}
                                                                       selected={values.country ? [{
                                                                           code: values.country,
                                                                           label: t("ENUMS.COUNTRY_CODES." + values.country)
                                                                       }] : []}
                                                            />
                                                        )}
                                                    </Field>
                                                </div>
                                                <div className="col-12 my-2">
                                                    <label htmlFor="phoneNumber"
                                                           className="form-label">{t("FORMS.CUSTOMER_DATA.PHONE")}</label>
                                                    <Field autoComplete="off"
                                                           className={getFormFieldValidationStyles(props.errors, props.touched, "phoneNumber")}
                                                           type="tel"
                                                           name="phoneNumber"
                                                           placeholder={t("FORMS.CUSTOMER_DATA.PHONE")}
                                                           required
                                                    />
                                                    <ErrorMessage name="phoneNumber"
                                                                  render={msg => <div
                                                                      className="invalid-feedback">{msg}</div>}/>
                                                </div>
                                                <div className="col-12 my-2">
                                                    <label htmlFor="email"
                                                           className="form-label">{t("FORMS.CUSTOMER_DATA.MAIL")}</label>
                                                    <Field autoComplete="off"
                                                           className={getFormFieldValidationStyles(props.errors, props.touched, "email")}
                                                           type="email"
                                                           name="email"
                                                           placeholder={t("FORMS.CUSTOMER_DATA.MAIL")}
                                                           required
                                                    />
                                                    <ErrorMessage name="email"
                                                                  render={msg => <div
                                                                      className="invalid-feedback">{msg}</div>}/>
                                                </div>
                                                <div className="col-12 my-2">
                                                    <label htmlFor="dateOfBirth"
                                                           className="form-label">{t("FORMS.CUSTOMER_DATA.BIRTHDAY")}</label>
                                                    <Field autoComplete="off"
                                                           className={getFormFieldValidationStyles(props.errors, props.touched, "dateOfBirth")}
                                                           type="date"
                                                           name="dateOfBirth"
                                                           placeholder={t("FORMS.CUSTOMER_DATA.BIRTHDAY")}
                                                    />
                                                    <ErrorMessage name="dateOfBirth"
                                                                  render={msg => <div
                                                                      className="invalid-feedback">{msg}</div>}/>
                                                </div>
                                            </div>
                                            <div className="text-end mt-3">
                                                <button type="submit" className="btn btn-primary">
                                                    {t("SETTINGS.PASSWORD.SAVE")}
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default UserData;
