export enum UnzerElementID {
    CARD_NUMBER = 'unzer-card-number',
    CARD_EXPIRY = 'unzer-card-expiry',
    CARD_CVC = 'unzer-card-cvc',
    CARD_HOLDER = 'unzer-card-holder',
    GENERAL_ERROR_HOLDER = 'unzer-error-holder',
    GOOGLEPAY_BUTTON = 'unzer-googlepay',
    GOOGLEPAY_ERROR = 'unzer-googlepay-error',
    GOOGLEPAY_SUCCESS = 'unzer-googlepay-success',
    PAYLATER_INVOICE = 'unzer-paylater-invoice',
    PAYLATER_INSTALLMENT = 'unzer-paylater-installment',
    GENERAL_FORM_ID = 'unzer-payment-form',
    RESOURCE_SUBMIT_BUTTON = 'unzer-resource-submit-btn',
}

//These class names are from unzer, don't change.
export enum UnzerHTMLClasses {
    FIELD = 'field',
    INPUT = 'unzerInput'
}

export interface UnzerPaymentDTO {
    paymentId: string;
    threatMetrixUUID: string;
    dateOfBirth?: Date | string;
}

export interface UnzerResultDTO {
    unzerResult: any;
}