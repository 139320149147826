import React, {useEffect, useState} from "react";
import {CartDTO, PaymentMethod} from "../../../../models/cart/CartDTO";
import {UnzerPaymentHelper} from "./UnzerPaymentHelper";
import {UnzerHtmlHelper} from "./UnzerHtmlHelper";
import {UnzerElementID} from "./UnzerModels";

export interface UnzerPaymentPluginProps {
    paymentMethod: PaymentMethod;
    cartDTO?: CartDTO;
    submitHandler: Function;
    loadingHandler: Function;
    threatMetrixUUID: string;
}


export default function UnzerPaymentPlugin({
                                               paymentMethod,
                                               cartDTO,
                                               submitHandler,
                                               loadingHandler,
                                               threatMetrixUUID
                                           }: UnzerPaymentPluginProps) {

    let clientId: string;
    useEffect(() => {
        checkClientId();

        if (document.getElementById('threat-metrix-script') != null || clientId === undefined) {
            return;
        }

        const script = document.createElement('script');

        script.src = `https://h.online-metrix.net/fp/tags.js?org_id=363t8kgq&session_id=${threatMetrixUUID}`;
        script.id = "threat-metrix-script"
        script.async = true;
        script.type = 'text/javascript';

        document.head.appendChild(script);

    }, []);


    useEffect(() => {
        checkClientId();
        if(clientId === undefined){
            return;
        }

        if (document.getElementById('unzer-script') != null) {
            return;
        }

        const script = document.createElement('script');
        script.id = "unzer-script"
        script.src = "https://static.unzer.com/v1/unzer.js";
        script.async = true;
        script.onload = () => {


            const unzer = new window.unzer(clientId);

            switch (paymentMethod) {

                case PaymentMethod.UNZER_CARD:
                    UnzerPaymentHelper.initializeCard(unzer, submitHandler, loadingHandler);
                    break;

                case PaymentMethod.UNZER_PAYLATER_INVOICE:
                    UnzerPaymentHelper.initializePayLaterInvoice(unzer, submitHandler, loadingHandler);
                    break;

                case PaymentMethod.UNZER_PAYLATER_INSTALLMENT:
                    UnzerPaymentHelper.initializePayLaterInstallment(unzer, submitHandler, loadingHandler, cartDTO);
                    break;
                case PaymentMethod.UNZER_GOOGLEPAY:
                    break;
                case PaymentMethod.UNZER_APPLEPAY:
                    break;
                case PaymentMethod.UNZER_PAYPAL:
                    break;
                default:
                    break;
            }
        };

        script.onerror = (e) => {
            console.log("Coudln't load UnzerPlugin.")
            const errorHolder = document.getElementById(UnzerElementID.GENERAL_ERROR_HOLDER);
            if (errorHolder) {
                errorHolder.innerHTML = e.toString();
            }
        }

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };

    }, []);

    function checkClientId(){
        if(typeof window !== 'undefined') {
            clientId = window.unzerClientId
        } else {
            clientId = process?.env?.UNZER_CLIENT_ID;
        }
    }

    function getFormElements() {
        switch (paymentMethod) {

            default:
                return <></>;

            case PaymentMethod.UNZER_PAYLATER_INVOICE:
                return UnzerHtmlHelper.getPayLaterInvoiceHtmlElements();

            case PaymentMethod.UNZER_PAYLATER_INSTALLMENT:
                return UnzerHtmlHelper.getPayLaterInstallmentHtmlElements();

            case PaymentMethod.UNZER_CARD:
                return UnzerHtmlHelper.getCardHtmlElements();

            case PaymentMethod.UNZER_APPLEPAY:
                return <></>; //Preparation for later

            case PaymentMethod.UNZER_GOOGLEPAY:
                return UnzerHtmlHelper.getGooglePayHtmlElements();

            case PaymentMethod.UNZER_PAYPAL:
                return <></>; //Preparation for later

        }
    }


    return <>
        {getFormElements()}
        <div className="field" id={UnzerElementID.GENERAL_ERROR_HOLDER}></div>
    </>
}