export class DateHelper {

    static isAdult(date: Date | string): boolean {
        if(typeof date === 'string'){
            date = new Date(date);
        }

        if(date === undefined){
            date = new Date();
        }

        const today = new Date();

        const age = today.getFullYear() - date.getFullYear();
        const monthDiff = today.getMonth() - date.getMonth();
        const dayDiff = today.getDate() - date.getDate();

        return age > 18 || (age === 18 && (monthDiff > 0 || (monthDiff === 0 && dayDiff >= 0)));

    }

}